<!-- =========================================================================================
     File Name: designationList.vue
    Description: Designation
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- DESIGNATION LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Status List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>-->
              <!-- <vx-tooltip text="Export Excel">
                <a
                  :href="excelurl+`/v1/designation/excel/download?token=${temp_token}&search=${searchQuery}`"
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>-->
              <!--
              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="excelurl+`/v1/designation/excel/download?token=${temp_token}&archive=1&search=${searchQuery}`"
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>-->
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="designations"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import ContractService from "@/services/ContractService.js";

import companyService from "@/services/companyService.js";
import { BASEURL } from "@/config/index.js";
import SearchService from "@/services/searchService.js";
import conStatus from "./conStatus.vue";
import editStatus from "./editStatus.vue";
import statusArchive from "./statusArchive.vue";
import budgetStatus from "./budgetStatus.vue";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL,
      archive: this.listType,
      User: "Select User",
      designations: [],
      totalPages: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" }
      ],
      columnDefs: [],
      contacts: contacts
    };
  },
  beforeMount() {
    eventBus.$on("conrefreshTableStatus", e => {
      console.log("eeeeee call");
      this.getWorkTypeList(this.currentPage);
    });
    this.columnDefs = [
      // {
      //   headerName: "Sr.No.",
      //   field: "firstname",
      //   width: 150,
      //   filter: false,
      //   checkboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   headerCheckboxSelection: true
      // },
      {
        headerName: "Status Name",
        field: "status_name",
        filter: false,
        width: 350,
        suppressSizeToFit: true
      },
      {
        headerName: "Color",
        field: "",
        filter: false,
        width: 120,
        suppressSizeToFit: true,

        cellStyle: function(params) {
          //  console.log("params PPPP :>> ", params.value);
          return {
            color: params.data.status_color,
            backgroundColor: params.data.status_color
            // height: "28px",
            // marginTop: "10px"
          };
        }
      },

      {
        headerName: "Status",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: conStatus
      },
      {
        headerName: "Budget Status",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: budgetStatus
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: editStatus
      },

      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        sortable: false,
        width: this.archive ? 160 : 150,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: statusArchive
      }
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getWorkTypeList(val);
          return val;
        } else return 1;
        // this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    // updateSearchQuery(val) {
    //   this.gridApi.setQuickFilter(val);
    // },
    updateSearchQuery(val) {
      SearchService.getAllSerchResultV2(val, "contractStatus", null)
        .then(response => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.designations = data.data;
          } else {
            this.designations = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getWorkTypeList: function(currentPage) {
      let filter = {
        page: currentPage
      };
      if (this.archive == "archive") {
        if (this.designations.length == 1) {
          this.designations = [];
        }

        filter["archive"] = true;
      }
      ContractService.getContractVendor(filter)
        .then(response => {
          const { data } = response;
          if (!data.err) {
            this.designations = data.data;

            this.totalPages = data.pagination.last_page;
            if (screen.width > 1200) {
              this.gridApi.sizeColumnsToFit();
            }
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.checkPermission(["designation", "GET"])) {
      this.getWorkTypeList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  }
};
</script>
